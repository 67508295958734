.email-confirm-box{
    width: 50%;
    height: 25vh;
    position: relative;
    left: 25%;
    right: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.email-confirm-box img{
    width: 125px;
    height: 70px
}
.email-confirm-box h1{
    font-family: 'Montserrat', sans-serif;
}
.email-confirm-box h2{
    font-family: 'Montserrat', sans-serif;
}
.email-confirm-box p{
    font-family: 'Sora', sans-serif;
    padding: 10px;
}
.email-confirm-box a{
    font-family: 'Sora', sans-serif;
    background-color: #f18a01;
    border-radius: 0.3rem;
    color: #ffff;
    text-decoration: none;
    padding: 5px;
}
.email-confirm-box a:hover{
    background-color: #2176D9;
    transition: 0.5s ease-in-out;
}
