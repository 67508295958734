@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";
body {
  margin: 0;
  padding: 0;
  background-color: #EEF1F3;
  background-size: cover;
}
.login-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #37474f;
}
.login-main-container img {
  margin-bottom: 0;
  padding: 20px;
}
.login-box {
padding: 30px;
display: flex;
min-width: 350px;
flex-direction: column;
align-items: center;
font-family: Arial, Helvetica, sans-serif;
background-color: rgba(242, 244, 245, 0.2);
color: #f18a01;
border: 1px solid grey;
 border-radius: 6px;
 -webkit-box-shadow: 0px 0px 76px 7px rgba(0, 0, 0, 0.42);
 box-shadow: 0px 0px 76px 7px rgba(0, 0, 0, 0.42);
}
.login-box h1 {
  font-size: 30px;
  text-align: center;
  color: #e5e5e5;
  padding-bottom: 10px;
}
hr {
  width: 100%;
  background-color: #0b6ca1;
  border: 2px solid #0b6ca1;
  margin-bottom: 20px;
}
.textBox {
  width: 100%;
  color: #e5e5e5;
  font-size: 18px;
  padding: 10px 2px;
}
.textBox i {
  color: #f18a01;
  font-size: 20px;
  margin-top: 7px;
}
.textBox input {
  color: white;
  font-size: 16px;
  margin: 0px 10px;
}
::placeholder {
  color: #37474f;
  font-size: 15px;
}
.btn {
  width: 80%;
  background-color: #f18a01;
}
p {
  font-size: 13px;
  text-align: center;
}