@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");

.todo-card {
  min-width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border: 0.01rem solid silver;
  border-radius: 7px;
  background-color: #fbfbfd;
  position: relative;
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.todo-card-header {
  padding: 8px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #2176d9;
}

.todo-card-header-name {
  float: left;
  padding: 20px;
  font-size: 1.1vw;
  color: white;
  font-family: "Roboto", sans-serif;
}

.todo-card-body {
  height: 238px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 2px;

}

#edit-todo-icon {
  position: absolute;
  color: grey;
  font-size: 12px;
  right: 4;
  transition: 0.3s;
}

#edit-todo-icon:hover {
  transition: 0.3s;
  color: black;
  background-color: whitesmoke;
  padding: 2px;
}
.todo-content a {
  background-image: linear-gradient(
    to right,
    rgb(4, 68, 97),
    rgb(4, 68, 97) 50%,
    #2f9ac2 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}
.todo-content a:hover {
  background-position: 0;
}

@media only screen and (max-width: 780px) {
  .todo-card-header-name {
    float: left;
    padding: 20px;
    font-size: 2.5vw;
    color: white;
    font-family: "Roboto", sans-serif;
  }
}

@media only screen and (max-width: 1600px) {
  .todo-card {
    height: 300px;
    min-width: 300px;
  }
}
