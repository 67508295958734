@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400&display=swap");

.my-profile {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.user-info-box {
  width: 60%;
  height: 25vh;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}
.user-info-box-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
}

.my-profile-box {
  width: 35%;
  min-height: 85%;
  position: absolute;
  top: 10%;
  min-height: 60vh;
  border-radius: 8px;
  display: flex;
  align-items: center;
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

@media only screen and (max-width: 500px) {
  .my-profile-box {
    width: 100%;
  }
}

@media only screen and (max-width: 1366px) {
  .my-profile-box {
    width: 60%;
    height: 80vh;
    top: 12%;
  }
}
@media only screen and (max-width: 1440px) {
  .my-profile-box {
    width: 55%;
    height: 85vh;
    top: 12%;
  }
}

#favFilter-item {
  color: "red";
}
