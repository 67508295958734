@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');

header {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #474644;
}

.search-box {
    width: 50%;
    height: auto;
    margin-left: 200px;
    color: black;
}

.avatar {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;

}

.avatar:hover {
    background-color: rgba(189, 189, 189, 0.3);
    transition: 0.3s;
}

.header-right {
    margin-left: auto;
    display: flex;
    width: 12%;
    height: 8vh;
    align-items: center;
}

.header-right-container {
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar-list-item-top {
    width: 100%; 
    height: 12px; 
    position: absolute;
    background-color: #474644;
    top: 0;
    left:0;
    bottom: -20px;
    border-radius: 0 0 20px 0;
}
.sidebar-list-item-bottom{
    width: 100%; 
    height: 12px; 
    position: absolute;
    background-color: #474644;
    left:0;
    bottom: 0px;
    border-radius: 0 20px 0 0;
}

@media only screen and (max-width: 1500px) {
    .header-right {
        width: 10%;
    }

    .avatar {
        padding: 0px;
    }

    .search-box {
        margin-left: 90px;
    }

}

@media only screen and (max-width: 500px) {
    .header-right {
        width: 25%;
    }

    .search-box {
        width: 80%;
        margin-left: 25px;
    }

    .avatar {
        padding: 0px;
        display: none;
    }

    img {
        display: none;
    }

}