@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Sora&display=swap');

* {

    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

body {
    background-color: #72716f;
}

.App {
    background-color: #fbfbfd;
}