@import url(https://fonts.googleapis.com/css2?family=Sora:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sora:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Sora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
@import url(https://use.fontawesome.com/releases/v5.5.0/css/all.css);
header {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #474644;
}

.search-box {
    width: 50%;
    height: auto;
    margin-left: 200px;
    color: black;
}

.avatar {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;

}

.avatar:hover {
    background-color: rgba(189, 189, 189, 0.3);
    transition: 0.3s;
}

.header-right {
    margin-left: auto;
    display: flex;
    width: 12%;
    height: 8vh;
    align-items: center;
}

.header-right-container {
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar-list-item-top {
    width: 100%; 
    height: 12px; 
    position: absolute;
    background-color: #474644;
    top: 0;
    left:0;
    bottom: -20px;
    border-radius: 0 0 20px 0;
}
.sidebar-list-item-bottom{
    width: 100%; 
    height: 12px; 
    position: absolute;
    background-color: #474644;
    left:0;
    bottom: 0px;
    border-radius: 0 20px 0 0;
}

@media only screen and (max-width: 1500px) {
    .header-right {
        width: 10%;
    }

    .avatar {
        padding: 0px;
    }

    .search-box {
        margin-left: 90px;
    }

}

@media only screen and (max-width: 500px) {
    .header-right {
        width: 25%;
    }

    .search-box {
        width: 80%;
        margin-left: 25px;
    }

    .avatar {
        padding: 0px;
        display: none;
    }

    img {
        display: none;
    }

}
.todo-card {
  min-width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border: 0.01rem solid silver;
  border-radius: 7px;
  background-color: #fbfbfd;
  position: relative;
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.todo-card-header {
  padding: 8px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #2176d9;
}

.todo-card-header-name {
  float: left;
  padding: 20px;
  font-size: 1.1vw;
  color: white;
  font-family: "Roboto", sans-serif;
}

.todo-card-body {
  height: 238px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 2px;

}

#edit-todo-icon {
  position: absolute;
  color: grey;
  font-size: 12px;
  right: 4;
  transition: 0.3s;
}

#edit-todo-icon:hover {
  transition: 0.3s;
  color: black;
  background-color: whitesmoke;
  padding: 2px;
}
.todo-content a {
  background-image: linear-gradient(
    to right,
    rgb(4, 68, 97),
    rgb(4, 68, 97) 50%,
    #2f9ac2 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}
.todo-content a:hover {
  background-position: 0;
}

@media only screen and (max-width: 780px) {
  .todo-card-header-name {
    float: left;
    padding: 20px;
    font-size: 2.5vw;
    color: white;
    font-family: "Roboto", sans-serif;
  }
}

@media only screen and (max-width: 1600px) {
  .todo-card {
    height: 300px;
    min-width: 300px;
  }
}


.my-profile {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.user-info-box {
  width: 60%;
  height: 25vh;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}
.user-info-box-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
}

.my-profile-box {
  width: 35%;
  min-height: 85%;
  position: absolute;
  top: 10%;
  min-height: 60vh;
  border-radius: 8px;
  display: flex;
  align-items: center;
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

@media only screen and (max-width: 500px) {
  .my-profile-box {
    width: 100%;
  }
}

@media only screen and (max-width: 1366px) {
  .my-profile-box {
    width: 60%;
    height: 80vh;
    top: 12%;
  }
}
@media only screen and (max-width: 1440px) {
  .my-profile-box {
    width: 55%;
    height: 85vh;
    top: 12%;
  }
}

#favFilter-item {
  color: "red";
}

* {

    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

body {
    background-color: #72716f;
}

.App {
    background-color: #fbfbfd;
}
body {
  margin: 0;
  padding: 0;
  background-color: #EEF1F3;
  background-size: cover;
}
.login-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #37474f;
}
.login-main-container img {
  margin-bottom: 0;
  padding: 20px;
}
.login-box {
padding: 30px;
display: flex;
min-width: 350px;
flex-direction: column;
align-items: center;
font-family: Arial, Helvetica, sans-serif;
background-color: rgba(242, 244, 245, 0.2);
color: #f18a01;
border: 1px solid grey;
 border-radius: 6px;
 box-shadow: 0px 0px 76px 7px rgba(0, 0, 0, 0.42);
}
.login-box h1 {
  font-size: 30px;
  text-align: center;
  color: #e5e5e5;
  padding-bottom: 10px;
}
hr {
  width: 100%;
  background-color: #0b6ca1;
  border: 2px solid #0b6ca1;
  margin-bottom: 20px;
}
.textBox {
  width: 100%;
  color: #e5e5e5;
  font-size: 18px;
  padding: 10px 2px;
}
.textBox i {
  color: #f18a01;
  font-size: 20px;
  margin-top: 7px;
}
.textBox input {
  color: white;
  font-size: 16px;
  margin: 0px 10px;
}
::-webkit-input-placeholder {
  color: #37474f;
  font-size: 15px;
}
:-ms-input-placeholder {
  color: #37474f;
  font-size: 15px;
}
::placeholder {
  color: #37474f;
  font-size: 15px;
}
.btn {
  width: 80%;
  background-color: #f18a01;
}
p {
  font-size: 13px;
  text-align: center;
}
.email-confirm-box{
    width: 50%;
    height: 25vh;
    position: relative;
    left: 25%;
    right: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.email-confirm-box img{
    width: 125px;
    height: 70px
}
.email-confirm-box h1{
    font-family: 'Montserrat', sans-serif;
}
.email-confirm-box h2{
    font-family: 'Montserrat', sans-serif;
}
.email-confirm-box p{
    font-family: 'Sora', sans-serif;
    padding: 10px;
}
.email-confirm-box a{
    font-family: 'Sora', sans-serif;
    background-color: #f18a01;
    border-radius: 0.3rem;
    color: #ffff;
    text-decoration: none;
    padding: 5px;
}
.email-confirm-box a:hover{
    background-color: #2176D9;
    transition: 0.5s ease-in-out;
}


.event-card-container {
    width: 1000px;
    height: 600px;
}
